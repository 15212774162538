import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import logoweb from '../images/logoweb.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="border-gray-200 bg-transparent">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <Link to="/" className="">
                    <img src={logoweb} className="h-10" alt="Logo" />
                </Link>
                <button
                    type="button"
                    onClick={toggleMenu}
                    className="inline-flex items-center p-2 w-12 h-12 justify-center text-lg rounded-lg md:hidden focus:outline-none focus:ring-2 text-white"
                    aria-controls="navbar-menu"
                    aria-expanded={isOpen}
                >
                    <span className="sr-only">Ouvrir le menu principal</span>
                   
                        <svg
                            className="w-8 h-8"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#ff3000"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    
                </button>
                <div className={`w-[98%] h-auto bg-transparent justify-center flex md:block md:w-auto ${isOpen ? 'block ' : 'hidden bg-transparent'}`} id="navbar-menu">
                    {/* Menu pour les grands écrans */}
                    <div className="hidden md:flex xs:flex-col flex-col font-medium mt-4 rounded-lg md:space-x-8 rtl:space-x-reverse lg:flex-row sm:flex-col xl:flex-row 2xl:flex-row md:flex-row md:mt-0 md:border-0">
                        <div>
                            <ScrollLink
                                to="Accueil"
                                smooth={true}
                                duration={200}
                                className="block py-2 px-3 md:p-0 text-white lg:text-black cursor-pointer"
                            >
                                Accueil
                            </ScrollLink>
                        </div>
                        <div>
                            <ScrollLink
                                to="pre-inscription"
                                smooth={true}
                                duration={200}
                                className="block py-2 px-3 md:p-0 text-white lg:text-black cursor-pointer"
                            >
                                Pré-inscription
                            </ScrollLink>
                        </div>
                        <div>
                            <ScrollLink
                                to="comment-ca-marche"
                                smooth={true}
                                duration={200}
                                className="block py-2 px-3 md:p-0 text-white lg:text-black cursor-pointer"
                            >
                                En savoir plus
                            </ScrollLink>
                        </div>
                        <div>
                            <a
                                href="mailto:hello@lapli.app"
                                className="block py-2 px-3 md:p-0 text-white lg:text-black"
                            >
                                Contact
                            </a>
                        </div>
                    </div>
                    {/* Menu pour les petits écrans */}
                    <div className={`md:hidden flex flex-col w-[96%] h-[96%] border-[#ff3000] border mx-auto mt-4 rounded-lg md:space-x-8 rtl:space-x-reverse lg:flex-row sm:flex-col xl:flex-row 2xl:flex-row md:flex-row md:mt-0 md:border-0 ${isOpen ? 'fixed inset-0 bg-[#ff3000] z-50' : 'hidden'}`}>
                        <div className="flex justify-end px-4 py-2  ">
                            <button
                                type="button"
                                onClick={toggleMenu}
                                className="text-[#fff] focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="#fff"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="flex flex-col items-center space-y-12  w-full font-medium text-lg ">
                            <ScrollLink
                                to="Accueil"
                                smooth={true}
                                duration={200}
                                className="block py-2 px-3 md:p-0 text-[#fff] lg:text-black cursor-pointer"
                                onClick={toggleMenu}
                            >
                                Accueil
                            </ScrollLink>
                            <ScrollLink
                                to="pre-inscription"
                                smooth={true}
                                duration={200}
                                className="block py-2 px-3 md:p-0 text-[#fff] lg:text-black cursor-pointer"
                                onClick={toggleMenu}
                            >
                                Pré-inscription
                            </ScrollLink>
                            <ScrollLink
                                to="comment-ca-marche"
                                smooth={true}
                                duration={200}
                                className="block py-2 px-3 md:p-0 text-[#fff] lg:text-black cursor-pointer"
                                onClick={toggleMenu}
                            >
                                En savoir plus
                            </ScrollLink>
                            <a
                                href="mailto:hello@lapli.app"
                                className="block py-2 px-3 md:p-0 text-[#fff] lg:text-black"
                                onClick={toggleMenu}
                            >
                                Contact
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;

