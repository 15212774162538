import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Confirmation from './pages/Confirmation';
const App = () => {
  const [confirmationMessage, setConfirmationMessage] = useState('');

  return (
    <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={<Accueil confirmationMessage={confirmationMessage} />}
      />
      <Route
        path="/confirm-email/:token"
        element={<Confirmation setConfirmationMessage={setConfirmationMessage} />}
      />
    </Routes>
  </BrowserRouter>
  );
};

export default App;
