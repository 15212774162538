const contenuCGU = `
<div style="font-family: Arial, sans-serif; color: #333;font-size: 15px;text-align: justify;   padding: 2px; ">
<h1 style="display: flex; justify-content: center; align-items: center; flex-direction: column;font-size: 17px; font-weight: bold; text-align:center; ">Conditions générales d’utilisation / RGPD LAPLi
</h1>
<br/>
<h1 style="text-decoration: underline;">ARTICLE 1 : Objet</h1><br/>

<p>Les Conditions Générales d’Utilisation (Ci-après les « Conditions Générales ») s’appliquent, sans
restriction ni réserve, entre LAPLI et toute personne physique ou morale domiciliée en France (Ci-après le
« Client ») pour tout achat de services fournis par l’intermédiaire du site internet « LAPLI.fr » ou par son
application LAPLI (Ci -après la plateforme LAPLI).</p><br>
<p>Les Conditions Générales sont applicables dès leur acceptation par le client et ne peuvent s’applique aux
transactions ultérieurement. Leur mise en ligne et ne peuvent s’appliquer aux transactions conclues
antérieurement. La version des Conditions Générales applicable à l&#39;achat du Client est celle en vigueur sur
LAPLI à la date de passation de la commande.</p><br/>
<p>Le Client déclare avoir pris connaissance des présentes Conditions Générales et les avoir acceptées en
cochant la case prévue à cet effet. Sauf preuve contraire, les données enregistrées dans le système
informatique de LAPLI constituent la preuve de l’ensemble des transactions conclues avec le Client via
LAPLI.</p><br/>
<h1 style="text-decoration: underline;">ARTICLE 2 : L’accès et l’utilisation de l’application ou du site internet sont soumis aux conditions et
modalités suivantes</h1>
<br/>
<p>- Le droit d&#39;usage concédé au Client est non exclusif et non cessible ;
</p>
<p>- LAPLI ne peut être utilisé que par le Client, pour ses propres besoins,
</p>
<p>- LAPLI peut être utilisé sur tout type de navigateur internet ainsi que sur Android, Windows mobile et IOS.</p>
<p>- L’application est conçue pour satisfaire au plus grand nombre d&#39;utilisateurs, LAPLI ne pouvant garantir son adaptation aux besoins spécifiques du Client,</p>
<p>- Le Client est considéré comme ayant les compétences nécessaires à la bonne utilisation de l’application ou du site Internet et sous sa propre responsabilité.</p>
<p>- Aucun droit de propriété intellectuelle sur LAPLI n’est cédé au Client, le site Internet et l’application LAPLI demeurant la propriété entière et exclusive de LAPLI.
</p><br/>
<h1 style="text-decoration: underline;">ARTICLE 3 : Données personnelles
</h1><br/>
<p>Nous vous demandons de saisir les données personnelles nécessaires (nom, prénom, courriel, département…). Nous vous expliquons ci-après quelles données nous collectons et utilisons et quelles données nous transmettons au professionnel de l’auto que vous avez choisi.</p>
<p>· Nous avons besoin de votre courriel afin de vous envoyer la confirmation, le détail de votre inscription.</p>
<p> De même, la responsabilité de LAPLI ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l&#39;utilisation du réseau Internet, notamment une rupture de service, une intrusion extérieure ou la
présence de virus informatiques.</p>
<h1 style="text-decoration: underline;">ARTICLE 4 : Contenu illicite</h1><br/>
<p>Tout contenu illicite doit être notifié par mail sur serviceclient@lapli.app
</p>
<p>Toute notification de contenus illicites auprès de LAPLI doit obligatoirement indiquer les informations suivantes conformément à l’article 6 I 5 ° de la loi 2004-575 du 21 juin 2004 :
</p>
<p>- La date de la notification,
</p>
<p>- Si le notifiant est une personne physique : ses nom, prénoms, profession, domicile, nationalité, date et lieu de naissance,</p>
<p>- Si le notifiant est une personne morale : sa forme, sa dénomination, son siège social et l’organe qui la représente légalement,</p>
<p>- Les noms et domicile du destinataire ou, s’il s’agit d’une personne morale, sa dénomination et son siège social,
</p>
<p>- La description des faits litigieux et leur localisation précise (avec copie de l’adresse URL précise du contenu),</p>
<p> - Les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de fait,
</p>
<p>- La copie de la correspondance adressée à l’auteur ou à l’éditeur des informations ou activités litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l’auteur ou
l’éditeur n’a pu être contacté.</p>
<p>Il est rappelé que le fait, pour toute personne, de présenter un contenu ou une activité comme étant illicite dans le but d&#39;en obtenir le retrait ou d&#39;en faire cesser la diffusion, alors qu&#39;elle sait cette information
inexacte, est puni d&#39;un an d&#39;emprisonnement et de 15 000 € d&#39;amende aux termes de l’article 6, I, 4° de la loi 2004-575 du 21 juin 2004.</p><br/>
<h1 style="text-decoration: underline;">ARTICLE 5 : Propriété intellectuelle
</h1>
<br/>
<p>Propriété du site et de son contenu</p>
<p>Le présent site Internet ainsi que ses textes et illustrations sont protégés par le droit d&#39;auteur. La reproduction et la publication sont permises en indiquant la source (www.lapli.app). Nous nous réservons le droit de modifier le présent site à tout moment.</p>
<p></p>
<p>Liens externes
</p>
<p></p>

<p>Le site peut contenir des liens vers d&#39;autres sites web détenus et administrés par des tiers. LAPLI ne peut
garantir la licéité, l&#39;exactitude, l&#39;exhaustivité et la qualité des informations qui y sont contenues. Toutefois,
nous nous engageons à supprimer tout lien vers ces sites tiers dès que nous aurions pris connaissance de
leur caractère manifestement illicite, inexact ou inadéquat. Pour tous liens inappropriés, adressez-nous un
mail sur <span style="color:red;">serviceclient@lapli.app.</span></p>
<p>Propriété intellectuelle
</p>
<p>Tout le contenu de LAPLI que ce soient les éléments visuels ou sonores, y compris la technologie sous-
jacente, est la propriété de LAPLI et de ses partenaires et est protégé par les lois françaises et
internationales relatives à la propriété intellectuelle. La divulgation des éléments du Site internet ou
application ne sauraient en aucun cas être interprétées comme accordant une licence ou un droit
d&#39;utilisation quelconque des éléments distinctifs de LAPLI protégés par le droit de la propriété intellectuelle
et notamment le droit d&#39;auteur.</p>

<p>Toute reproduction, représentation, utilisation, diffusion, publication, modification totale ou partielle du
contenu du Site Internet ou application, pour quelque motif, par quelque procédé et sur quelque support
que ce soit, est strictement interdite et est susceptible de constituer un délit de contrefaçon. Ainsi aucun
des documents provenant du Site ou de l’application ne peut être copié, reproduit, publié, téléchargé,
posté, transmis ou distribué d&#39;aucune manière que ce soit.</p>
<p>Pour les services fournis par LAPLI, les droits de reproduction et de représentation des travaux constitutifs
d’une œuvre au sens du droit de la propriété intellectuelle sont cédés au Client pour la durée de protection
des droits de propriété intellectuelle portant sur l&#39;œuvre et pour le monde entier.</p>
Le Client s&#39;interdit donc toute reproduction ou exploitation desdites études, dessins, modèles, etc., sans
l&#39;autorisation expresse, écrite et préalable qui peut le conditionner à une contrepartie financière.
<br/>
<h1 style="text-decoration: underline;">ARTICLE 6 : Protection des données personnelles et cookies</h1>
<br/>
<p>Dans le cadre de l’exécution du présent Contrat et/ou de la navigation sur le Site internet et l’application, un
certain nombre de données personnelles relative au Client pourront être amenées à être traitées par LAPLI.</p>
<p>Pour davantage d’information sur le traitement de ces données et les droits des Visiteurs et des Clients sur
les données les concernant, les Visiteurs et Clients sont invités à lire attentivement la Politique de
confidentialité.</p>
<p>Pour LAPLI, les données personnelles sont traitées en toute confidentialité et que les toutes les
dispositions relatives à la protection des données sont prises en compte. Nous vous précisons donc
exactement quelles données sont traitées dans le cadre de la location de voiture. En acceptant les
conditions générales, le Client permet par là même l’enregistrement et l’utilisation de ses données dans le
cadre précisé ci-après. Toutes les informations fournies par le Client pour réserver la voiture de location
sont transmises cryptées par SSL.</p>
<p></p>
<p>Collecte et finalité du traitement
</p>
<p></p>
<p>Les données collectées sur le site, par le biais des formulaires de renseignement, et nécessaires au
traitement de la demande du client, seront ensuite transférées aux sociétés tierces qui sont les
fournisseurs et ce, dans le respect des dispositions applicables en matière de la protection des données à
caractère personnel. Notamment, ces données seront transmises dans des conditions garantissant leur
intégrité, leur sécurité et l&#39;absence de possibilité d&#39;interception par un tiers. Le Client autorise la
transmission de ces données et reconnaît que LAPLI n&#39;est pas responsable des conditions d&#39;utilisation ou
de la non-utilisation de ces données par le fournisseur.</p>
<p></p>
Données collectées,
<p></p>
<p>Lors d&#39;une réservation de voiture de location : Pour procéder à une réservation de voiture de location, nous
vous demandons les informations personnelles suivantes : Nom, adresse, adresse de courrier
électronique, numéro de téléphone, date de naissance et informations de paiement.</p>
<p>L’adresse e-mail du Client est essentielle lors du processus de réservation, sachant que l’ensemble de la
correspondance entre LAPLI et le Client se fait sans utilisation de papier. Cet e-mail est particulièrement
important puisque contenant toutes les informations relatives à la location du véhicule.</p>
<p>Une fois la location effectuée, le Client est prié d’évaluer les prestations de location et est informé de la procédure à suivre en cas de réclamations.</p>
<p></p>
Droit d&#39;accès
<p></p>

<p>Toute personne justifiant de son identité a le droit d&#39;interroger le responsable du traitement en vue d&#39;obtenir
la confirmation que les données à caractère personnel la concernant font ou ne font pas l&#39;objet de ce
traitement pour exercer votre droit d&#39;accès, de modification et d&#39;opposition, vous pouvez contacter la
société LAPLI par mail sur <span style="color:'#red">serviceclient@lapli.app</span>.</p>
<p>Le consommateur est informé qu&#39;il peut s&#39;inscrire sur la liste d&#39;opposition au démarchage téléphonique sur bloctel.gouv.fr
</p>
<p></p>
Cookies
<p></p>
<p>Nous utilisons des Cookies, pour reconnaître si vous avez déjà visité notre site. Ce Cookie est stocké pour
30 jours sur votre disque dur et automatiquement supprimé. En outre, LAPLI utilise l&#39;outil de la société
Google qui s’appelle Analytics. Celui-ci produit également des Cookies, sur lesquels nous n&#39;avons pas de
contrôle, et qui peuvent avoir une durée de validité de plusieurs années. Vous trouvez d&#39;autres informations
sous http://www.google.fr/intl/fr/privacy.html. Vous pouvez refuser l’installation des cookies sur votre
ordinateur, mais un tel refus peut empêcher l’accès à certains services du site.</p><br/>
<h1 style="text-decoration: underline;">ARTICLE 7 : Acceptation du client
</h1>
<br/>
<p>Les présentes Conditions Générales sont expressément agréées et acceptées par le Client, qui déclare et reconnaît en avoir une parfaite connaissance.
</p>
<h1 style="text-decoration: underline;">ARTICLE 8 : Contactez-nous
</h1>
<br/>
<p>LAPLI est édité et gérée par EASY WEB MOBILE, inscrite au registre du commerce de Tarbes sous le numéro de SIREN 424 891 612 00048 – APE 6201Z (Ci-après « LAPLI »).</p>
<p>Son adresse de siège social est : 3 Place Mailho, 65380 Bénac.
Tel : 09</p>
<p>Le service clientèle de LAPLI est disponible par courriel sur serviceclient@lapli.app.
</p><br/>
<div>

</div>
`

export default contenuCGU;
