
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import GridLoader from "react-spinners/GridLoader";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Confirmation = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(`https://lapli:lazpdr56TY78@lapli.app/admin/public/api/confirm-emaill/${token}`);
        if (response.status === 200) {
          navigate('/?success=true'); // Redirect with success parameter
        } else {
          navigate('/'); // Redirect to home page without success parameter
        }
      } catch (error) {
        navigate('/'); // Redirect to home page without success parameter
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return (
    <div className='flex justify-center bg-white items-center h-[100vh] w-[100vw]'>
      <GridLoader
        color='#ff3200'
        cssOverride={override}
        size={20}
      /></div>
  );
};

export default Confirmation;
